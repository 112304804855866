import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import breakpoint from '@utils/breakpoint'
import Copy from '@objects/copy'
import Headline from '@objects/headline'
import clsx from 'clsx'
import Circle3px from '@static/img/tracklines/circles/circle-3px-fullline-vertical.inline.svg'

const RowItem = styled.div.attrs({
  className: '',
})`
  width: 100%;

  ${breakpoint('md')`
    width: calc(50% + 1px);
  `}

  &.rowItem-left {
    ${breakpoint('md')`
      width: calc(50% + 2px);
      align-self: flex-start;
    `}
  }

  &.rowItem-last {
    ${breakpoint('md')`
      width: 100%;
      text-align: center;
    `}
  }

  &:not(.rowItem-first) {
    ${breakpoint('md')`
      margin-top: -1px;
    `}
  }
`

const VerticalBottomLine = styled.div.attrs({
  className: '',
})`
  display: none;

  .separatorIcon {
    line,
    circle {
      stroke: ${({ theme }) => theme.colors.yellow.default};
    }

    ${breakpoint('md')`
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: -11px;
      left -11px;
    `}
  }

  ${breakpoint('md')`
    display: block;
    position: relative;
    width: calc(50% + 1px);
    height: 50px;
    margin-left: auto;
    border-left: solid 3px ${({ theme }) => theme.colors.yellow.default};
  `}
`

const StyledHeadline = styled(Headline).attrs({
  className: '',
})`
  width: 100%;
  border-left: solid 3px ${({ theme }) => theme.colors.yellow.default};
  padding-left: 32px;
  padding-bottom: 4px;
  padding-top: 40px;

  ${breakpoint('md')`
    padding-left: 42px;
    padding-right: 72px;
  `}

  .rowItem-left & {
    ${breakpoint('md')`
      border-right: solid 3px ${({ theme }) => theme.colors.yellow.default};
      border-left: 0;
      padding-left: 72px;
      padding-right: 42px;
    `}
  }

  .rowItem-first + ${RowItem} & {
    ${breakpoint('md')`
      border-top-right-radius: 11px;
    `}
  }

  .rowItem-last & {
    ${breakpoint('md')`
      border-right: 0;
      border-left: 0;
      padding-left: 0;
      padding-right: 0;
      padding-top: 19px;
    `}
  }
`

const CopySection = styled.div.attrs({
  className: '',
})`
  width: 100%;
  border-left: solid 3px ${({ theme }) => theme.colors.yellow.default};

  ${breakpoint('md')`
    border-top: solid 3px ${({ theme }) => theme.colors.yellow.default};
  `}

  .rowItem-left & {
    ${breakpoint('md')`
      border-right: solid 3px ${({ theme }) => theme.colors.yellow.default};
      border-left: 0;
    `}
  }

  .rowItem-first & {
    ${breakpoint('md')`
      border-top-right-radius: 11px;
    `}
  }

  .rowItem-last & {
    ${breakpoint('md')`
      border-top: 0;
      border-left: 0;
      border-right: 0;
    `}
  }
`

const StyledCopy = styled(Copy).attrs({
  className: '',
})`
  padding-top: 4px;
  padding-left: 32px;
  ${breakpoint('md')`
    line-height: ${({ theme }) => theme.lineHeight['8']};
    padding-left: 42px;
    padding-right: 72px;
  `}

  .rowItem-left & {
    ${breakpoint('md')`
      padding-left: 72px;
      padding-right: 42px;
    `}
  }

  .rowItem-last & {
    ${breakpoint('md')`
      padding-left: 0;
      padding-right: 0;
    `}
  }
`

const HorizontalSeparator = styled.div.attrs({
  className: 'flex flex-row',
})`
  background-color: ${({ theme }) => theme.colors.yellow.default};
  height: 3px;
  width: ${(props) => props.customWidth || '100'}%;
  flex-shrink: 0;
  flex-grow: 1;
  margin-top: 3px;
  margin-bottom: 3px;
  position: relative;

  .separatorIcon {
    position: absolute;
    top: -8px;
    width: 20px;
    height: 20px;

    line,
    circle {
      stroke: ${({ theme }) => theme.colors.yellow.default};
    }

    ${breakpoint('md')`
      top: -11px;
    `}
  }

  .separatorIconStart {
    left -11px;
  }

  .separatorIconEnd {
    right: -11px;
  }

  .rowItem-last & {
    ${breakpoint('md')`
      display: none;
    `}
  }

  ${breakpoint('md')`
    width: 100%;
    height: 0;
    margin-top: 0;
    margin-bottom: 0;
  `}
`
const MainElement = styled.div.attrs({
  className: '',
})`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 48px;
  margin-bottom: 48px;

  .rowItem-first,
  .rowItem-last {
    .separatorIconStart {
      transform: rotate(90deg);
    }

    ${HorizontalSeparator} {
      height: 0;
      margin-top: 0;
      margin-bottom: 0;
    }

    .separatorIconEnd {
      display: none;
    }
  }

  .rowItem-first {
    ${StyledHeadline} {
      border-left-color: transparent;
      padding-top: 0;

      ${breakpoint('md')`
        border-right: 0;
      `}
    }
    .separatorIconStart {
      ${breakpoint('md')`
        transform: none;
      `}
    }
  }

  .rowItem-last {
    ${CopySection} {
      border-left-color: transparent;
    }

    .separatorIcon {
      ${breakpoint('md')`
        transform: rotate(90deg);
      `}
    }
  }

  .rowItem-left .separatorIconEnd line,
  ${RowItem}:not(.rowItem-left) .separatorIconStart line {
    ${breakpoint('md')`
      stroke: transparent
    `}
  }
`
function Timeline({ className, headlines, copies }) {
  return (
    <MainElement>
      {headlines.map((headline, index) => {
        if (!!copies[index]) {
          return (
            <RowItem
              data-testid="rowItem"
              key={index}
              className={clsx({
                'rowItem-first': index === 0,
                'rowItem-last': index === headlines.length - 1,
                'rowItem-left':
                  index % 2 === 0 && index !== headlines.length - 1,
              })}
            >
              {index === headlines.length - 1 && (
                <VerticalBottomLine>
                  <Circle3px className="separatorIcon" />
                </VerticalBottomLine>
              )}
              <StyledHeadline level={3}>{headline}</StyledHeadline>
              <CopySection>
                <HorizontalSeparator
                  customWidth={Math.ceil(Math.random() * 30) + 70}
                >
                  <Circle3px className="separatorIcon separatorIconStart" />
                  <Circle3px className="separatorIcon separatorIconEnd" />
                </HorizontalSeparator>
                <StyledCopy>{copies[index]}</StyledCopy>
              </CopySection>
            </RowItem>
          )
        }
        return null
      })}
    </MainElement>
  )
}

Timeline.propTypes = {
  className: PropTypes.string,
  headlines: PropTypes.arrayOf(PropTypes.string).isRequired,
  copies: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default Timeline
